import React, { useState } from 'react';
import {
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import TaskList from './TaskList';
import AddTask from './AddTask';
import { TasksProvider } from './TasksContext';
import PageHeader from '../../components/appShell/PageHeader';
//   import { SortableItem } from './SortableItem';

function Test() {
  return (
    <>
      <PageHeader headline="Test" />

      <TasksProvider>
        <h1>Day off in Kyoto</h1>
        <AddTask />
        <TaskList />
      </TasksProvider>
    </>
  );
}

export default Test;
