import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';
import CollectionsTable from '../../components/pages/CollectionsTable';
import Empty from '../../components/placeholders/Empty';
import PagesTable from '../../components/pages/PagesTable';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ContentDetail() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  console.log(params.collection);
  const [collectionContent, setCollectionContent] = useState([]);
  const [empty, isEmpty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCollectionContent = () => {
    api.get(`/${params.collection}`)
      .then((data) => {
        console.log(data);
        setCollectionContent(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };

  useEffect(() => {
    // getCollections();
    getCollectionContent();
  }, [params.collection]);

  return (

    <>
      {isLoading && <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4" /> }
      {!isLoading && <PageHeader headline={params.collection.charAt(0).toUpperCase() + params.collection.slice(1)} saveButton="New" saveButtonTarget="./add" />}

      {collectionContent.length === 0
                && <Empty headline={params.collection.charAt(0).toUpperCase() + params.collection.slice(1)} />}
      {collectionContent.length > 0
                && <PagesTable content={collectionContent} />}
    </>

  );
}

export default ContentDetail;
