import axios from 'axios';
import Cookies from 'js-cookie'

const api = axios.create({
  // baseURL: "https://api2.dogtrack.io",
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: `Bearer ${Cookies.get('token')}`,
  },
});

export default api;
