import { useState } from 'react';
import { useContentStore } from '../../services/contentContext';

export default function RichText({ block }) {
  console.log(block);
  const [input, setInput] = useState();
  const [changed, setChanged] = useState(false);
  const [editing, setEditing] = useState(false);

  const contentStore = useContentStore();

  const save = () => {
    contentStore.editContent({
      type: 'richtext',
      id: block.id,
      data: {
        name: input,
      },
    });
    setChanged(false);
    setEditing(false);
  };

  const deleteOne = () => {
    contentStore.removeContent({
      id: block.id,
    });
    console.log(block.id);
  };

  return (
    <div key={block.id} className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Content Name</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            {editing
                                                && (
                                                <input
                                                  onChange={(e) => { setInput(e.target.value); setChanged(true); }}
                                                  defaultValue={block.data.name}
                                                  type="email"
                                                  name="email"
                                                  id="email"
                                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                  placeholder="you@example.com"
                                                />
                                                )}
            {!editing
                        && (
                        <p>
                          <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">

                            {block.data.name}
                          </span>
                        </p>
                        )}

          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            {changed
                            && (
                            <button
                              onClick={() => save()}
                              type="button"
                              className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Save
                            </button>
                            )}
            {!changed
                        && (
                        <button
                          onClick={() => setEditing(true)}
                          type="button"
                          className="mr-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Edit
                        </button>
                        )}
            <button
              onClick={() => deleteOne()}
              type="button"
              className="mr-3 inline-flex items-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  // return (
  //     <>
  //         <div key={block.id}>
  //             <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
  //                 <div class="flex">

  //                     <div>
  //                         <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
  //                             Input Name
  //                         </label>
  //                         <div className="mt-2">
  //                             <input
  //                                 onChange={(e) => { setInput(e.target.value); setChanged(true)}}
  //                                 defaultValue={block.data.name}
  //                                 type="email"
  //                                 name="email"
  //                                 id="email"
  //                                 className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
  //                                 placeholder="you@example.com"
  //                             />
  //                         </div>
  //                     </div>

  //                 </div>
  //                 {changed &&
  //                         <button
  //                             onClick={() => save()}
  //                             type="button"
  //                             className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //                         >
  //                             Save
  //                         </button>
  //                     }
  //                     <button
  //                             onClick={() => deleteOne()}
  //                             type="button"
  //                             className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
  //                         >
  //                             Delete
  //                         </button>
  //             </li>
  //         </div>
  //     </>
  // )
}
