import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import NavItem from '../blocksOld/NavItem';

import Media from '../collectionBlocks/Media';
import Markdown from '../collectionBlocks/Markdown';
import RichText from '../collectionBlocks/RichText';
import Input from '../collectionBlocks/Input';
import Reference from '../collectionBlocks/Reference';

export function Item({ block, media, collections }) {
  console.log(block);

  if (block.type === 'input') {
    return (
      <Input block={block} />
    );
  }

  if (block.type === 'media') {
    return (
      <Media block={block} media={media} />
    );
  }

  if (block.type === 'markdown') {
    return (
      <Markdown block={block} media={media} />
    );
  }

  if (block.type === 'richtext') {
    return (
      <RichText block={block} media={media} />
    );
  }

  if (block.type === 'reference') {
    return (
      <Reference block={block} media={media} collections={collections} />
    );
  }
}
