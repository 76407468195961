import { useState, useContext } from 'react';
import {
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTasks, useTasksDispatch } from './TasksContext.js';

export default function TaskList() {
  const dispatch = useTasksDispatch();
  const tasks = useTasks();
  return (

    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={HandleDragEnd}
    >

      <SortableContext
        items={tasks}
        strategy={verticalListSortingStrategy}
      >
        {/* We need components that use the useSortable hook */}

        <ul className="space-y-3">
          {tasks.map((task) => (
            <li key={task.id}>
              <SortableItem task={task} />
            </li>
          ))}
        </ul>
      </SortableContext>
    </DndContext>

  );
  function HandleDragEnd(event) {
    console.log('Drag end called');
    const { active, over } = event;
    console.log(`ACTIVE: ${active.id}`);
    console.log(`OVER :${over.id}`);

    // console.log(tasks)

    if (active.id !== over.id) {
      dispatch({
        type: 'moved',
        active: active.id,
        over: over.id,
      });
      //   setTasks((items) => {
      //     const activeIndex = items.indexOf(active.id);
      //     const overIndex = items.indexOf(over.id);
      //     console.log(arrayMove(items, activeIndex, overIndex));
      //     return arrayMove(items, activeIndex, overIndex);
      //     // items: [2, 3, 1]   0  -> 2
      //     // [1, 2, 3] oldIndex: 0 newIndex: 2  -> [2, 3, 1]
      //   });
    }
  }
}

function Task({ task }) {
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useTasksDispatch();
  let taskContent;
  if (isEditing) {
    taskContent = (
      <>
        <input
          className="block w-96 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"

          value={task.text}
          onChange={(e) => {
            dispatch({
              type: 'changed',
              task: {
                ...task,
                text: e.target.value,
              },
            });
          }}
        />
        <button
          type="button"
          className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"

          onClick={() => setIsEditing(false)}
        >
          Save
        </button>
      </>
    );
  } else {
    taskContent = (
      <>
        {task.text}
        <button
          type="button"
          className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"

          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
      </>
    );
  }
  return (
    <label>
      {taskContent}
      <button
        type="button"
        className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => {
          dispatch({
            type: 'deleted',
            id: task.id,
          });
        }}
      >
        Delete
      </button>
    </label>
  );
}

export function SortableItem({ task }) {
  // props.id
  // JavaScript

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: task.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
        <div className="flex">
          <button {...attributes} {...listeners} className="mr-6">
            ⣿
          </button>
          {/* <NavItem defaultValue={props.content.block.content} saveAll={props.saveAll} changed={props.changed}/> */}
          <Task task={task} />
        </div>

      </div>
    </div>
  );
}
