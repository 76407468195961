import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import camelCase from 'camelcase';
import { toast } from 'react-toastify';
import PageHeader from '../../../components/appShell/PageHeader';
import api from '../../../services/api';
import User from '../../../hooks/auth/user';

function AddContent({ user }) {
  const navigate = useNavigate();
  const params = useParams();
  const contentCollection = params.collection.toLowerCase();
  // console.log(params)
  const [contentName, setContentName] = useState('');
  const [contentApiName, setContentApiName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  const [definedBlocks, setDefinedBlocks] = useState([]);
  // const user = User();
  // console.log(user)

  const { t, i18n } = useTranslation();

  const getCollectionBlocks = () => {
    api.get(`/collections?slug=${contentCollection}`)
      .then((data) => {
        // console.log(data)
        setDefinedBlocks(data.data[0].blocks);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  const createCollectionContent = () => {
    api.post(`/${contentCollection}`, {
      name: contentName,
      apiName: contentApiName,
      description,
      // status: "Open",
      createdBy: user.name,
      org: user.org,
      projectId: params.projectId,
      blocks: definedBlocks,
    })
      .then((res) => {
        console.log(res);
        navigate(`/projects/${params.projectId}/content/${params.collection}/${res.data.insertedId}`);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getCollectionBlocks();
  }, []);

  return (
    <>
      {/* <AppShell /> */}
      <PageHeader headline={`Add Content to ${params.collection.charAt(0).toUpperCase() + params.collection.slice(1)}`} saveButtonActionText="Save" saveButtonAction={createCollectionContent} />
      {/* Add Form */}
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Content Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">

                    <input

                      onChange={(e) => {
                        setContentName(e.target.value);
                        setContentApiName(camelCase(e.target.value));
                      }}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Project Name"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Content API Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">

                    <input
                                            // onChange={(e) => setProjectApiName(e.target.value)}
                      value={contentApiName}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Project Api Name"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    defaultValue=""
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddContent;
