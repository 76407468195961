import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { nanoid } from 'nanoid';
import { useContentStore } from '../../services/contentContext';

const inputs = [
  {
    id: 1, title: 'Input', description: 'Last message sent an hour ago', users: '621 users', type: 'input',
  },
  {
    id: 2, title: 'Media', description: 'Last message sent 2 weeks ago', users: '1200 users', type: 'media',
  },
  {
    id: 3, title: 'Markdown', description: 'Last message sent 4 days ago', users: '2740 users', type: 'markdown',
  },
  {
    id: 3, title: 'Rich Text Editor', description: 'Last message sent 4 days ago', users: '2740 users', type: 'richtext',
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function BlocksList() {
  const contentStore = useContentStore();
  const [selectedInput, setSelectedInput] = useState();

  const addOne = () => {
    contentStore.addContent({
      type: selectedInput.type,
      id: nanoid(),
      data: {},
    });
  };

  return (
    <>
      <RadioGroup value={selectedInput} onChange={setSelectedInput}>
        <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
          Select a mailing list
        </RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-1 sm:gap-x-4">
          {inputs.map((input) => (
            <RadioGroup.Option
              key={input.id}
              value={input}
              className={({ active }) => classNames(
                active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
              )}
            >
              {({ checked, active }) => (
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                        {input.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                        {input.description}
                      </RadioGroup.Description>
                      <RadioGroup.Description as="span" className="mt-6 text-sm font-medium text-gray-900">
                        {input.users}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  <CheckCircleIcon
                    className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                    aria-hidden="true"
                  />
                  <span
                    className={classNames(
                      active ? 'border' : 'border-2',
                      checked ? 'border-indigo-600' : 'border-transparent',
                      'pointer-events-none absolute -inset-px rounded-lg',
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <button
        type="button"
        className="ml-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        onClick={() => addOne()}
      >
        Insert
      </button>
    </>
  );
}
