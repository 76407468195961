import { useEffect, useState } from 'react';
import { useContentStore } from '../../services/contentContext';
import RichTextEditorComponent from '../inputs/RichTextEditorComponent';

export default function RichText({ block }) {
  console.log(block);
  const [input, setInput] = useState();
  const [changed, setChanged] = useState(false);
  const [data, setData] = useState(block.data.data);

  const contentStore = useContentStore();

  const save = () => {
    contentStore.editContent({
      type: 'richtext',
      id: block.id,
      data: {
        name: block.data.name,
        data,
      },
    });
    setChanged(false);
  };

  const deleteOne = () => {
    contentStore.removeContent({
      id: block.id,
    });
    console.log(block.id);
  };

  useEffect(() => {
    setChanged(true);
  }, [data]);

  return (
    <div key={block.id} className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{block.data.name}</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="w-full text-sm text-gray-500">
            <div className="editor">
              <RichTextEditorComponent data={data} onChange={setData} editorblock="editorjs-container" />

            </div>
          </div>

        </div>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div />
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            {changed
                            && (
                            <button
                              onClick={() => save()}
                              type="button"
                              className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Save
                            </button>
                            )}

          </div>
        </div>
      </div>
    </div>
  );
}
