import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';
import CollectionsTable from '../../components/pages/CollectionsTable';
import WebhooksTable from '../../components/pages/WebhooksTable';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function Webhooks() {
    const { t, i18n } = useTranslation();
    const [webhooks, setWebhooks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getWebhooks = () => {
        api.get('/webhooks')
            .then((data) => {
                console.log(data);
                setWebhooks(data.data);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: 'bottom-left',
                    autoClose: 2000,
                });
                // console.log(error.message)
            });
    };

    useEffect(() => {
        getWebhooks();
    }, []);
    return (
        <>
            <PageHeader headline="Webhooks" saveButton="New" saveButtonTarget="./add" />
            <WebhooksTable webhooks={webhooks} />
        </>
    );
}

export default Webhooks;
