import { createContext, useContext, useReducer } from 'react';
import contentReducer, { initialState } from './contentReducer';

const ContentContext = createContext(initialState);

export function ContentProvider({ children }) {
  const [state, dispatch] = useReducer(contentReducer, initialState);

  const addToContent = (addContent) => {
    // console.log(addContent)
    // const updatedContent = state.content.concat(addContent);

    dispatch({
      type: 'ADD',
      payload: {
        blocks: addContent,
      },
    });
  };

  const value = {
    storedBlocks: state.blocks,
    addToContent,
  };

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
}

const useContent = () => {
  const context = useContext(ContentContext);

  if (context === undefined) {
    throw new Error('useContent must be used within ContentContext');
  }

  return context;
};

export default useContent;
