import { toJS } from 'mobx';

export function createContentStore() {
  return {
    content: [],
    addContent(block) {
      this.content.push({
        block,
      });
      console.log(toJS(this.content));
    },
    clearContent() {
      this.content.length = 0;
    },
    editContent(changedContent) {
      console.log(changedContent);
      console.log(toJS(this.content));
      // const content = toJS(this.content)
      const index = this.content.findIndex((content) => content.block.id === changedContent.id);
      console.log(index);
      this.content = this.content.filter((content) => content.block.id !== changedContent.id);
      this.content.splice(index, 0, { block: changedContent });
      console.log(toJS(this.content));
    },
    removeContent(id) {
      console.log(id);
      this.content = this.content.filter((content) => content.block.id !== id.id);
    },
  };
}
