import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';
import CollectionsTable from '../../components/pages/CollectionsTable';

function Collections() {
  const { t, i18n } = useTranslation();
  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getCollections = () => {
    api.get('/collections')
      .then((data) => {
        console.log(data);
        setCollections(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };

  useEffect(() => {
    getCollections();
  }, []);
  return (
    <>
      <PageHeader headline="Collections" saveButton="New" saveButtonTarget="./add" />
      <CollectionsTable collections={collections} />
    </>
  );
}

export default Collections;
