import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import { toast } from 'react-toastify';
import api from '../../services/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function OrgSettings({user}) {
  const [loaded, setLoaded] = useState(false);
  const [changes, setChanges] = useState(false);
  const [org, setOrg] = useState([]);
  const [orgId, setOrgId] = useState([]);
  const [orgName, setOrgName] = useState('');
  const [orgUrl, setOrgUrl] = useState('');
  const [orgDescription, setOrgDescription] = useState('');
  const tabs = [
    { name: 'My Account', to: '../account', current: false },
    { name: 'Organisation', to: './', current: true },
  ];

  const getOrg = () => {
    api.get(`/org/${user.org}`)
      .then((data) => {
        console.log(data);
        setOrg(data.data[0]);
        setOrgId(data.data[0]._id);
        setOrgName(data.data[0].name);
        setOrgDescription(data.data[0].description);
        setOrgUrl(data.data[0].url);
        setLoaded(true)
      });
  };

  const updateOrg = () => {
    api.patch(`/org/${orgId}`, {
      name: orgName,
      description: orgDescription,
      url: orgUrl,
    })
      .then((data) => {
        console.log(data);
        setChanges(false);
        toast.success('Saved', {
          position: 'bottom-left',
          autoClose: 2000,
        });
      })

      .catch((err) => {
        toast.error(err, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getOrg();
  }, []);

  useEffect(() => {
    setChanges(true);
  }, [orgName, orgUrl, orgDescription]);

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}

                  to={tab.to}
                  className={classNames(
                    tab.current
                      ? 'border-green-500 text-green-600'
                      : 'border-transparent text-gray-500 dark:text-white hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {/* FORM */}
      {loaded &&
      <div className="mt-6">
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Organisation</h2>
              {/* <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p> */}
            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">
                    <input
                      defaultValue={org.name}
                      onChange={(e) => setOrgName(e.target.value)}
                      type="text"
                      name="website"
                      id="website"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              <div className="sm:col-span-4">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Website
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">
                    <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
                    <input
                      defaultValue={org.url}
                      onChange={(e) => setOrgUrl(e.target.value)}
                      type="text"
                      name="website"
                      id="website"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  About
                </label>
                <div className="mt-2">
                  <textarea
                    defaultValue={org.description}
                    onChange={(e) => setOrgDescription(e.target.value)}
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:bg-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                  />
                </div>
                {/* <p className="mt-3 text-sm leading-6 text-gray-600">Write a few sentences about yourself.</p> */}
              </div>

            </div>
          </div>

        </div>
        {changes
                    && (
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                      <button
                        type="button"
                        onClick={() => setChanges(false)}
                        className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => updateOrg()}
                        className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      >
                        Save
                      </button>
                    </div>
                    )}

      </div>
      }
    </>
  );
}

export default OrgSettings;
