import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProvider from './hooks';
import { ContentProvider } from './services/contentContext';
import './i18n';
import { PostHogProvider } from 'posthog-js/react';

// replace console.* for disable log on production
if (process.env.REACT_APP_NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  opt_in_site_apps: true,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <BrowserRouter>
      <AppProvider>
        <ContentProvider>
          <App />
        </ContentProvider>
      </AppProvider>
    </BrowserRouter>
  </PostHogProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
