import './App.css';
import React from 'react';
import {
  Routes, Route, useNavigate, Navigate,
} from 'react-router';
import { ProtectRoutes } from './hooks/protectredRoutes';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { Flowbite } from 'flowbite-react';

import { useEffect, useState } from 'react';
import User from './hooks/auth/user';
import { useCookies } from 'react-cookie';
import i18n from './i18n';
import Test from './pages/test/Test';
import Shell from './components/appShell/ShellProject';
import Settings from './pages/settings/Settings';
import Collections from './pages/collections/Collections';
import AddCollection from './pages/collections/AddCollection';
import EditCollection from './pages/collections/EditCollection';
import ShellSubNav from './components/appShell/ShellSubNav';
import Content from './pages/content/Content';
import ContentDetail from './pages/content/ContentDetail';
import EditContent from './pages/content/Content/EditContent';
import ProfileSettings from './pages/settings/ProfileSettings';
import OrgSettings from './pages/settings/OrgSettings';
import Upload from './pages/upload/Upload';
import AddProject from './pages/projects/AddProject';
import ProjectDashboard from './pages/projects/Dashboard';
import ShellGeneral from './components/appShell/ShellGeneral';
import AddUpload from './pages/upload/AddUpload';
import Register from './pages/Register';
import AddContent from './pages/content/Content/AddContent';
import { useHotkeys } from 'react-hotkeys-hook';
import { ContentProvider } from './pages/content/Content/ContentContext';
import Terminal from './components/appShell/Terminal';
import { posthog } from 'posthog-js';
import Webhooks from './pages/webhooks/Webhooks';

function App() {
  const [sidebar, setSidebar] = useState('');
  const [ terminal, setTerminal] = useState(false)
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  useHotkeys('meta+k', () => toggleTerminal());

  // if (!User().user.email){
  //   navigate("/login")
  // }
  const { user } = User();
  if (user) {
    posthog.identify(user._id, {
      email: user.email,
      name: user.name
    })
  
    posthog.group(user.org
    )

  }



  const toggleTerminal = () => {
    if (terminal) {
      setTerminal(false)
      console.log("FALSE")
    }
    if (!terminal) {
      setTerminal(true)
      console.log("TRUE")
    }
  }
  // const orgName = User().user.orgName;
  console.log(user);
  // const [ dark, setDark ] = useState();
  // check for colour theme
  useEffect(() => {
    colorTheme();
    // getCollections();
    // cookieLng();
    document.body.classList.add('h-full', 'bg-white', 'dark:bg-gray-900');
  }, []);

  // chekc for language cookie
  const cookieLng = () => {
    if (cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    }
    console.log(cookies);
  };

  // functioncheck and set color theme from local storage
  const colorTheme = () => {
    if (localStorage.getItem('color-theme') === 'dark') {
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-color-mode', 'dark');
      console.log('dark');
    }
    if (localStorage.getItem('color-theme') === 'light') {
      document.documentElement.classList.add('light');
      document.documentElement.setAttribute('data-color-mode', 'light');
    }
  };

  return (
    <>
    <Terminal isOpen={terminal}/>
    <Flowbite>
      <ContentProvider>
        <Routes>
          {/* Login */}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route element={<ProtectRoutes />}>
            <Route element={<ShellGeneral />}>
              <Route path="/test" element={<Test />} />
              <Route path="/" element={<Dashboard user={user} />} />
              <Route path="/settings" element={<Settings user={user} />}>
                <Route path="/settings" element={<Navigate to="/settings/account" replace />} />
                <Route path="/settings/account" element={<ProfileSettings user={user} />} />
                <Route path="/settings/organisation" element={<OrgSettings user={user} />} />
              </Route>
            </Route>

            <Route element={<Shell sidebar={sidebar} />}>
              <Route path="/projects/add" element={<AddProject user={user} />} />
              <Route path="/projects/:projectId" element={<ProjectDashboard user={user} />} />
              <Route path="/projects/:projectId/collections" element={<Collections user={user} />} />
              <Route path="/projects/:projectId/collections/add" element={<AddCollection user={user} />} />
              <Route path="/projects/:projectId/collections/:id" element={<EditCollection user={user} />} />
              <Route path="/projects/:projectId/upload" element={<Upload user={user} />} />
              <Route path="/projects/:projectId/upload/add" element={<AddUpload user={user} />} />
              <Route path="/projects/:projectId/webhooks" element={<Webhooks user={user} />} />
            </Route>

            <Route element={<ShellSubNav sidebar={sidebar} />}>
              <Route path="/projects/:projectId/content" element={<Content user={user} />} />
              <Route path="/projects/:projectId/content/:collection" element={<ContentDetail user={user} />} />
              <Route path="/projects/:projectId/content/:collection/add" element={<AddContent user={user} />} />
              <Route path="/projects/:projectId/content/:collection/:id" element={<EditContent user={user} />} />
            </Route>

          </Route>
        </Routes>
      </ContentProvider>
    </Flowbite>
    </>
  );
}

export default App;
