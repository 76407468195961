import { Fragment, useEffect, useState } from 'react';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  Bars3CenterLeftIcon,
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  PencilSquareIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Outlet, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import DarkModeSwitcherButton from '../ui/DarkModeSwitcherButton';
import User from '../../hooks/auth/user';
import PageHeader from './PageHeader';
import api from '../../services/api';
import Breadcrumbs from './Breadcrumbs';

const navigation = [
  {
    name: 'Dashboard', to: '/', icon: HomeIcon, current: false,
  },
  {
    name: 'Collections', to: '/collections', icon: Bars3CenterLeftIcon, current: false,
  },
  {
    name: 'Content', to: '/content', icon: PencilSquareIcon, current: false,
  },
  {
    name: 'Media', to: '/upload', icon: FolderIcon, current: false,
  },
];

const teams = [
  {
    id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false,
  },
  {
    id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false,
  },
  {
    id: 3, name: 'Workcation', href: '#', initial: 'W', current: false,
  },
];
const userNavigation = [
  { name: 'Your profile', href: '#' },
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ShellSubNav({
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState();
  const { user } = User();
  const location = useLocation();
  const params = useParams();

  const [collections, setCollections] = useState([]);

  const getCollections = () => {
    api.get('/collections')
      .then((data) => {
        console.log(data);
        setCollections(data.data);
        // setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };

  useEffect(() => {
    getCollections();
    sidebarStatus();
  }, []);

  // check sidebar status
  const sidebarStatus = () => {
    if (localStorage.getItem('sidebar') == 'true') {
      console.log('OPEN');
      setSidebarExpanded(true);
      document.getElementById('sidebar').classList.remove('lg:w-16');
      document.getElementById('sidebar').classList.add('lg:w-72');
      document.getElementById('contentContainer').classList.remove('lg:pl-16');
      document.getElementById('contentContainer').classList.add('lg:pl-72');
    }
    if (localStorage.getItem('sidebar') == 'false') {
      console.log('CLOSED');
      setSidebarExpanded(false);
      document.getElementById('sidebar').classList.add('lg:w-16');
      document.getElementById('sidebar').classList.remove('lg:w-72');
      document.getElementById('contentContainer').classList.add('lg:pl-16');
      document.getElementById('contentContainer').classList.remove('lg:pl-72');
    }
  };

  const toggleSidebar = () => {
    if (sidebarExpanded) {
      setSidebarExpanded(false);
      localStorage.setItem('sidebar', false);
      document.getElementById('sidebar').classList.add('lg:w-16');
      document.getElementById('sidebar').classList.remove('lg:w-72');
      document.getElementById('contentContainer').classList.add('lg:pl-16');
      document.getElementById('contentContainer').classList.remove('lg:pl-72');
    }
    if (!sidebarExpanded) {
      setSidebarExpanded(true);
      localStorage.setItem('sidebar', true);
      document.getElementById('sidebar').classList.remove('lg:w-16');
      document.getElementById('sidebar').classList.add('lg:w-72');
      document.getElementById('contentContainer').classList.remove('lg:pl-16');
      document.getElementById('contentContainer').classList.add('lg:pl-72');
    }
  };

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white dark:bg-gray-900 ">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900  px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a

                                  href={item.href}

                                  className={classNames(
                                    item.current
                                      ? 'bg-gray-50 dark:bg-gray-800 text-green-700'
                                      : 'text-gray-700 dark:text-white hover:text-green-700 hover:bg-gray-50 dark:hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-green-700' : 'text-gray-400 dark:text-white group-hover:text-green-700',
                                      'h-6 w-6 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400 dark:text-white">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-50 dark:bg-gray-800 text-green-700'
                                      : 'text-gray-700 dark:text-white hover:text-green-700 hover:bg-gray-50 dark:hover:bg-gray-800',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-green-700 border-green-700'
                                        : 'text-gray-400 dark:text-white border-gray-200 dark:border-gray-600  group-hover:border-green-700 group-hover:text-green-700',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white dark:bg-gray-900 ',
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <Link
                            to="/settings"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-green-700"
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-gray-400 dark:text-white group-hover:text-green-700"
                              aria-hidden="true"
                            />
                            Settings
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop  */}
        <div id="sidebar" className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-gray-600  bg-white dark:bg-gray-900  px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center dark:text-white">
              {/* <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=green&shade=600"
                alt="Your Company"
              /> */}
              🚀
              {' '}
              {sidebarExpanded && 'Siterocket'}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  {sidebarExpanded
                    && (
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={`/projects/${params.projectId}${item.to}`}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? 'text-green-600' : 'text-gray-400 group-hover:text-green-600',
                                'h-6 w-6 shrink-0',
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    )}
                  {!sidebarExpanded
                    && (
                    <ul role="list" className="-mx-2 space-y-1">
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={`/projects/${params.projectId}${item.to}`}
                            className={classNames(
                              item.current
                                ? 'bg-gray-50 text-indigo-600'
                                : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                              'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? 'text-green-600' : 'text-gray-400 group-hover:text-green-600',
                                'h-6 w-6 shrink-0',
                              )}
                              aria-hidden="true"
                            />
                          </Link>
                        </li>
                      ))}
                    </ul>
                    )}

                </li>

                <li className="mt-auto">
                  <Link
                    to="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-800 hover:text-green-700"
                  >
                    <Cog6ToothIcon
                      className="h-6 w-6 shrink-0 text-gray-400 dark:text-white group-hover:text-green-700"
                      aria-hidden="true"
                    />
                    {sidebarExpanded && 'Settings'}
                  </Link>
                  <button
                    className="justify-right"
                    onClick={() => toggleSidebar()}
                  >
                    {sidebarExpanded
                      && <ChevronDoubleLeftIcon className="h-6 w-6 shrink-0 text-gray-400 dark:text-white group-hover:text-green-700" />}
                    {!sidebarExpanded
                      && <ChevronDoubleRightIcon className="h-6 w-6 shrink-0 text-gray-400 dark:text-white group-hover:text-green-700" />}

                  </button>
                </li>

              </ul>
            </nav>
          </div>
        </div>

        <div id="contentContainer" className="lg:pl-16">
          <div className="sticky top-0 z-60 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 dark:border-gray-600  bg-white dark:bg-gray-900  px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 dark:text-white lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end">
              {/* {location.pathname} */}
              <div className="relative flex flex-1 pt-2.5 z-60">
                {/* {location.pathname} */}
                <Breadcrumbs />
              </div>
              {/* <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400 dark:text-white"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="bg-white dark:bg-gray-900 block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 dark:text-white placeholder:text-gray-400 dark:text-white focus:ring-0 sm:text-sm"
                  placeholder="Search..."
                  type="search"
                  name="search"
                />
              </form> */}
              {/* <DarkModeSwitcherButton /> */}

              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button type="button" className="-m-2.5 p-2.5 text-gray-400 dark:text-white dark:text-white hover:text-gray-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <DarkModeSwitcherButton />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50 dark:bg-gray-800"
                      src={user.avatarUrl}
                      alt=""
                    />
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-white" aria-hidden="true">
                        Mike
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-900  py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <a
                              href={item.href}
                              className={classNames(
                                active ? 'bg-gray-50 dark:bg-gray-800' : '',
                                'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-white',
                              )}
                            >
                              {item.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <div id="secondSidebar" className="hidden lg:fixed lg:inset-y-0  lg:flex lg:w-48 lg:flex-col pt-16">
            <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 dark:border-gray-600  bg-white dark:bg-gray-900 pt-4 pb-4">
              <ul role="list" className="-mx-2 space-y-1">
                {collections.map((item) => (
                  <li key={item.name}>
                    <Link
                      to={`/projects/${params.projectId}/content/${item.slug}`}
                      // to={"content/"+item.slug}
                      className={classNames(
                        item.current
                          ? 'bg-gray-50 text-indigo-600'
                          : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                        'group flex gap-x-3 rounded-md px-6 py-2 text-sm leading-6 font-semibold',
                      )}
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="pl-48">
            <main className="py-10">
              <div className="px-4 sm:px-6 lg:px-8">
                <ToastContainer />
                <Outlet />
              </div>
            </main>
          </div>

        </div>
      </div>
    </>
  );
}
