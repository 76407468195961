import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// import moment from 'moment';
import dayjs from 'dayjs';

function PagesTable(props) {
  const pages = props.content;
  console.log(pages);
  const { t, i18n } = useTranslation();
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Modified
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Slug
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  API Name
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {pages.map((item) => (
                <tr key={item._id}>
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                    {item.name}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {dayjs(item.date).format('YYYY-MM-DD')}
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{item.slug}</td>
                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                      {item.apiName}
                    </span>
                  </td>
                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                    <Link to={`./${item._id}`} className="text-green-600 hover:text-green-900">
                      Edit
                      <span className="sr-only">
                        ,
                        {item.name}
                      </span>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  // <div className="mt-8 flow-root">
  //     <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
  //         <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
  //             <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
  //                 <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">
  //                     <thead className="bg-gray-50 dark:bg-gray-800">
  //                         <tr>
  //                             <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 dark:text-white">
  //                                 {t('pages.table.pageName')}
  //                             </th>
  //                             <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">
  //                                 {t('pages.table.pageTitle')}
  //                             </th>
  //                             <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">
  //                                 {t('pages.table.pageStatus')}
  //                             </th>
  //                             <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white">
  //                                 {t('pages.table.pageUrl')}
  //                             </th>
  //                             <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
  //                                 <span className="sr-only">{t('pages.editButton')}</span>
  //                             </th>
  //                         </tr>
  //                     </thead>
  //                     <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-900">
  //                         {pages.map((item, index) => (
  //                             <tr key={item._id}>
  //                                 <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-6">
  //                                     {item.name}
  //                                 </td>
  //                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">{item.title}</td>
  //                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">
  //                                     {
  //                                         item._status === 'published' &&
  //                                         <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{item._status}</span>
  //                                     }
  //                                     {
  //                                         item._status === 'draft' &&
  //                                         <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{item._status}</span>
  //                                     }
  //                                 </td>
  //                                 <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-white">{item.slug}</td>
  //                                 <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
  //                                     <Link to={"./" + item._id} className="text-green-600 hover:text-green-900">
  //                                         Edit<span className="sr-only">, {item.name}</span>
  //                                     </Link>
  //                                 </td>
  //                             </tr>
  //                         ))}
  //                     </tbody>
  //                 </table>
  //             </div>
  //         </div>
  //     </div>
  // </div>
  );

  // return (
  //     <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
  //         <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  //             <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
  //                 <tr>
  //                     <th scope="col" class="px-6 py-3">
  //                         {t('pages.table.pageName')}
  //                     </th>

  //                     <th scope="col" class="px-6 py-3">
  //                         <div class="flex items-center">
  //                         {t('pages.table.pageTitle')}
  //                             <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
  //                         </div>
  //                     </th>
  //                     <th scope="col" class="px-6 py-3">
  //                         <div class="flex items-center">
  //                         {t('pages.table.pageStatus')}
  //                             <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
  //                         </div>
  //                     </th>
  //                     <th scope="col" class="px-6 py-3">
  //                         <div class="flex items-center">
  //                         {t('pages.table.pageUrl')}
  //                             <a href="#"><svg xmlns="http://www.w3.org/2000/svg" class="w-3 h-3 ml-1" aria-hidden="true" fill="currentColor" viewBox="0 0 320 512"><path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" /></svg></a>
  //                         </div>
  //                     </th>
  //                     <th scope="col" class="px-6 py-3">
  //                         <span class="sr-only">{t('pages.editButton')}</span>
  //                     </th>
  //                 </tr>
  //             </thead>
  //             <tbody>
  //                 {
  //                     props.pages.map((item, index) => {
  //                         return (
  //                             <tr key={index} class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
  //                                 <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
  //                                     {item.name}
  //                                 </th>
  //                                 <td class="px-6 py-4">
  //                                     {item.title}
  //                                 </td>
  //                                 <td class="px-6 py-4">
  //                                     {
  //                                         item._status === 'published' &&
  //                                         <span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{item._status}</span>
  //                                     }
  //                                     {
  //                                         item._status === 'draft' &&
  //                                         <span class="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">{item._status}</span>
  //                                     }

  //                                 </td>
  //                                 <td class="px-6 py-4">
  //                                     {item.slug}
  //                                 </td>
  //                                 <td class="px-6 py-4 text-right">
  //                                     <Link to={"/pages/" + item._id} class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{t('pages.editButton')}</Link>
  //                                 </td>
  //                             </tr>)
  //                     })
  //                 }
  //             </tbody>
  //         </table>
  //     </div>

  // )
}

export default PagesTable;
