import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
// import i18next, { changeLanguage } from 'i18next';

import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import i18n from '../../i18n';
import DarkModeSwitcherButton from '../../components/ui/DarkModeSwitcherButton';
import PageHeader from '../../components/appShell/PageHeader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Settings() {
  console.log(i18n.language);

  const languages = [
    { id: 1, name: 'German', value: 'de' },
    { id: 2, name: 'English', value: 'en' },
  ];

  // const found = languages.find(e => e.value === i18next.language)
  // console.log(found)

  const [editing, setEditing] = useState(false);
  const [changes, setChanges] = useState(false);
  const [selected, setSelected] = useState({});
  const [cookies, setCookies] = useCookies();
  const [lng, setLng] = useState(i18n.language);

  const findLanguage = () => {
    const found = languages.find((e) => e.value === i18n.language);
    setSelected(found);
  };
  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLng(language);
    setCookies('i18nextLng', language);
  };

  useEffect(() => {
    findLanguage();
  }, []);

  const saveAll = () => {
    changeLanguage(selected.value);
    setChanges(false);
    toast.success('Settings updated!', {
      position: 'bottom-left',
      autoClose: 2000,
    });
  };

  return (
    <>
      {/* <PageHeader headline={"Settings"} editButton={"Edit"} editButtonOnClick={}/> */}
      <div className="md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="dark:text-white text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            Settings
          </h2>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          {/* <button
                            onClick={() => setEditing(true)}
                            type="button"
                            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                            Cancel
                        </button> */}
          {changes
                        && (
                        <button
                          onClick={() => saveAll()}
                          type="button"
                          className="ml-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Save
                        </button>
                        )}

        </div>
      </div>
      <Outlet />
      {/* <div className="mt-6 border-t border-gray-100 dark:border-gray-700  ">
                <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Language</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Listbox value={selected} onChange={setSelected}>
                                {({ open }) => (
                                    <>
                                        <div className="relative mt-2 max-w-xs">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md dark:text-white dark:bg-gray-800 bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                                <span className="block truncate">{selected.name}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {languages.map((item) => (
                                                        <Listbox.Option
                                                            key={item.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-green-600 text-white' : 'text-gray-900 dark:text-white',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={item}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                        {item.name}
                                                                    </span>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-green-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Appearance</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><DarkModeSwitcherButton /></dd>
                    </div>
                    {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">margotfoster@example.com</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Salary expectation</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">$120,000</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">About</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                            qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                            pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                        </dd>
                    </div>
                </dl>
            </div> */}
    </>
  );
}

export default Settings;
