import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import { toast } from 'react-toastify';
import PageHeader from '../components/appShell/PageHeader';
import AppShell from '../components/appShell/AppShell';
import TopNav from '../components/appShell/TopNav';
import api from '../services/api';
import Stats from '../components/pages/Stats';

function Dashboard() {
  const { t, i18n } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAnalytics = () => {
    api.get('/analytics')
      .then((data) => {
        console.log(data);
        setAnalytics(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };
  const getProjects = () => {
    api.get('/projects')
      .then((data) => {
        console.log(data);
        setProjects(data.data);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };

  useEffect(() => {

    setTimeout(()=> {
      getProjects();
      getAnalytics();
    }, 50)

  }, []);

  const statuses = {
    Complete: 'text-green-700 bg-green-50 ring-green-600/20',
    'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
    Open: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  };


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  return (
    <>
      {/* <AppShell /> */}
      <PageHeader headline={t('dashboard.title')} />
      {/* Stats */}
      <h3 className="py-6 text-base font-semibold leading-6 text-gray-900">Current Month</h3>
     <Stats analytics={analytics}/>
      {/* Projects */}
      {/* <h2 className=" text-xl py-6 text-base font-semibold leading-6 text-gray-900">Projects</h2> */}
      {/* Heading */}
      <div className="pt-16 md:flex md:items-center md:justify-between">
        <div className=" min-w-0 flex-1">
          <h3 className="text-xl font-bold leading-7 text-gray-900 sm:truncate  sm:tracking-tight">
            Projects
          </h3>
        </div>
        <div className="mt-4 flex md:ml-4 md:mt-0">
          <Link to="/projects/add">
            <button
              type="button"

              className="ml-3 inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
            >
              Add
            </button>
          </Link>
        </div>
      </div>
      {/* Projects */}
      { isLoading ? (<div>Loading...</div>) : (<></>)}
      <ul role="list" className="divide-y divide-gray-100">
        {projects.map((project) => (
          <li key={project._id} className="flex items-center justify-between gap-x-6 py-5">
            
            <div className="min-w-0">
            <Link to={`/projects/${project._id}`}>
              <div className="flex items-start gap-x-3">
                <p className="text-sm font-semibold leading-6 text-gray-900">{project.name}</p>
                <p
                  className={classNames(
                    statuses[project.status],
                    'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                  )}
                >
                  {project.status}
                </p>
              </div>
              </Link>
              <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p className="whitespace-nowrap">
                  Created on
                  {' '}
                  <time dateTime={project.date}>{project.date}</time>
                </p>
                <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                <p className="truncate">
                  by 
                  {" "+project.createdBy}
                </p>
              </div>
            </div>
            <div className="flex flex-none items-center gap-x-4">
              <Link
                to={`/projects/${project._id}`}
                className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
              >
                View project
                <span className="sr-only">
                  ,
                  {project.name}
                </span>
              </Link>
              <Menu as="div" className="relative flex-none">
                <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                  <span className="sr-only">Open options</span>
                  <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                          )}
                        >
                          Edit
                          <span className="sr-only">
                            ,
                            {project.name}
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                          )}
                        >
                          Move
                          <span className="sr-only">
                            ,
                            {project.name}
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="#"
                          className={classNames(
                            active ? 'bg-gray-50' : '',
                            'block px-3 py-1 text-sm leading-6 text-gray-900',
                          )}
                        >
                          Delete
                          <span className="sr-only">
                            ,
                            {project.name}
                          </span>
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </li>
        ))}
      </ul>

    </>
  );
}

export default Dashboard;
