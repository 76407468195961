import React, { useEffect, useState } from 'react';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { useContentStore } from '../../services/contentContext';

function Markdown({ block }) {
  const [input, setInput] = useState(block.data.data);
  const [changed, setChanged] = useState(false);
  // console.log(props);
  const contentStore = useContentStore();

  const save = () => {
    contentStore.editContent({
      type: 'markdown',
      id: block.id,
      data: {
        name: block.data.name,
        data: input,
      },
    });
    setChanged(false);
  };

  const deleteOne = () => {
    contentStore.removeContent({
      id: block.id,
    });
    console.log(block.id);
  };

  return (
    <div key={block.id} className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{block.data.name}</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="w-full text-sm text-gray-500">
            <MDEditor

              value={input}

              onChange={(e) => { setInput(e); setChanged(true); }}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
              visibleDragbar={false}
              height={500}
            />
            {/* <MDEditor.Markdown source={input} style={{ whiteSpace: 'pre-wrap' }} /> */}
          </div>

        </div>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div />
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            {changed
                            && (
                            <button
                              onClick={() => save()}
                              type="button"
                              className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Save
                            </button>
                            )}

          </div>
        </div>
      </div>
    </div>
  );
}

export default Markdown;
