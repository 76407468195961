import React, { Fragment, useState, useEffect } from 'react';
import { MoonIcon, SunIcon } from '@heroicons/react/24/outline';

function DarkModeSwitcherButton() {
  useEffect(() => {
    colorTheme();
  }, []);

  const colorTheme = () => {
    if (localStorage.getItem('color-theme') === 'dark') {
      setDark(true);
    }
    if (localStorage.getItem('color-theme') === 'light') {
      setDark(false);
    }
  };

  const [dark, setDark] = useState();

  const toggleTheme = () => {
    if (!dark) {
      setDark(true);
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-color-mode', 'dark');
      // localStorage.removeItem('color-theme', 'light')
      localStorage.setItem('color-theme', 'dark');
    }
    if (dark) {
      setDark(false);
      document.documentElement.classList.remove('dark');
      document.documentElement.setAttribute('data-color-mode', 'light');
      // localStorage.removeItem('color-theme', 'dark')
      localStorage.setItem('color-theme', 'light');
    }
  };

  return (
    <>
      {
                dark
                && (
                <button
                  onClick={() => toggleTheme()}
                  type="button"
                  className="text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm p-2 mr-2  dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <SunIcon class="h-6 w-6 text-gray-500" />
                </button>
                )

            }
      {
                !dark
                && (
                <button
                  onClick={() => toggleTheme()}
                  type="button"
                  className="text-gray-900 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm p-2 mr-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                >
                  <MoonIcon class="h-6 w-6 text-gray-500" />
                </button>
                )

            }
    </>

  );
}

export default DarkModeSwitcherButton;
