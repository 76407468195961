import React, { useEffect, useState, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import camelCase from 'camelcase';
import { useObserver } from 'mobx-react';
import { Dialog, Transition, Switch } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { toJS } from 'mobx';
import BlocksList from '../../components/collectionBlocks/BlocksList';
import { Item } from '../../components/collections/Item';
import { useContentStore } from '../../services/contentContext';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditCollection = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const contentStore = useContentStore();

  console.log(toJS(contentStore.content));

  const [enabled, setEnabled] = useState(false);

  const [collections, setCollections] = useState([]);
  const [collectionName, setCollectionName] = useState('');
  const [collectionApiName, setCollectionApiName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');

  const [media, setMedia] = useState([]);

  const [open, setOpen] = useState(false);

  const [pendingChanges, setPendingChanges] = useState(false);

  const getCollectionById = () => {
    api.get(`/collections/${params.id}`)
      .then((data) => {
        contentStore.clearContent();
        console.log(data.data);
        setCollectionName(data.data.name);
        setCollectionApiName(data.data.apiName);
        setDescription(data.data.description);
        setSlug(data.data.slug);
        setEnabled(data.data.isIdentical);
        data.data.blocks.map((block) => contentStore.addContent(block.block));
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  const getMedia = () => {
    api.get('/upload')
      .then((data) => {
        setMedia(data.data);
        console.log(media);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  const updateCollection = () => {
    api.patch(`/collections/${params.id}`, {
      name: collectionName,
      isIdentical: enabled,
      description,
      blocks: toJS(contentStore.content),
    })
      .then((data) => {
        console.log(data);
      });
    toast.success('Saved', {
      position: 'bottom-left',
      autoClose: 2000,
    });
  };

  const deleteCollection = () => {
    api.delete(`/collections/${params.id}`)
      .then((res) => {
        console.log(res);
        navigate(`/projects/${params.projectId}/collections`);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  const getCollections = () => {
    api.get('/collections')
      .then((data) => {
        setCollections(data.data);
        // console.log(media)
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getCollections();
    getCollectionById();
    getMedia();
  }, []);

  useEffect(() => {
    setPendingChanges(true);
  }, [contentStore.content]);

  const saveChanges = () => {
    console.log('saved');
    setPendingChanges(false);
    updateCollection();
  };

  return useObserver(() => (
    <>
      {/* --------------------------- SLIDE OVER --------------------------- */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pt-16 pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Add Block
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <BlocksList />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <PageHeader headline={`Edit ${collectionName}`} pendingChanges={pendingChanges} pendingChangesText="Save" pendingChangesAction={saveChanges} deleteButtonText="Delete" deleteButtonAction={deleteCollection} collectionApiName={collectionApiName} />

      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Collection Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">

                    <input
                      defaultValue={collectionName}
                      onChange={(e) => {
                        setCollectionName(e.target.value);
                        setPendingChanges(true);
                      }}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Collection Name"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <Switch.Group as="div" className="flex items-center justify-between">

                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-green-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <span className="flex flex-grow flex-col ml-6">
                    <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                      Identical Content Structure
                    </Switch.Label>
                    <Switch.Description as="span" className="text-sm text-gray-500">
                      Set to yes if all the content in this collection will have the same structure.
                    </Switch.Description>
                  </span>
                </Switch.Group>

              </div>

              <div className="sm:col-span-4">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    defaultValue={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setPendingChanges(true);
                    }}
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>

              </div>
            </div>
          </div>
          {enabled
                        && (
                        <>
                          <div className="flex justify-between">
                            <h3 className="text-xl font-semibold leading-6 text-gray-900">Content</h3>
                            <div className="justify-end">
                              <button
                                type="button"
                                onClick={() => setOpen(true)}
                                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              >
                                Add Content
                              </button>
                            </div>

                          </div>
                          <ul role="list" className="space-y-3">
                            {contentStore.content.map((item) => (
                              <Item media={media} block={item.block} collections={collections} />
                            ))}
                          </ul>
                        </>
                        )}
        </div>

      </form>

    </>
  ));
};

export default EditCollection;
