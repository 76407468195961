import { useState } from 'react';
import Select from 'react-select';
import { toJS } from 'mobx';
import { useContentStore } from '../../services/contentContext';

export default function Media({ block, media }) {
  // console.log(toJS(block.data.media.thumbnailUrl))
  const [editing, setEditing] = useState(false);
  const mediaSelect = media.map((media) => ({
    id: media._id,
    value: { media },
    label: media.name,
  }));

  console.log(mediaSelect);
  const [selectedOption, setSelectedOption] = useState(null);
  // const selected = block.data.media._id ? mediaSelect.filter(mediaSelect => mediaSelect.id === block.data.media._id) : null;
  // setSelectedOption(selected)

  // if (block.data.media._id) {
  //     () => {setSelectedOption({"id": block.data.media._id, "label": block.data.media.name})}
  // }

  console.log(selectedOption);
  const [input, setInput] = useState();
  const [changed, setChanged] = useState(false);

  const contentStore = useContentStore();

  const save = () => {
    contentStore.editContent({
      type: 'media',
      id: block.id,
      data: {
        name: block.data.name,
        media: selectedOption.value.media,
      },
    });
    setChanged(false);
    setEditing(false);
  };

  const deleteOne = () => {
    contentStore.removeContent({
      id: block.id,
    });
    console.log(block.id);
  };

  return (
    <div key={block.id} className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{block.data.name}</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            {editing
                                               && (
                                               <div className="w-96 flex">
                                                 {!selectedOption
                                                && (
                                                <>
                                                  {block.data.media && <img className="h-16 w-16" src={block.data.media.thumbnailUrl} />}
                                                </>
                                                )}
                                                 {selectedOption
                                               && <img className="h-16 w-16" src={selectedOption.value.media.thumbnailUrl} />}

                                                 <Select
                                                   defaultValue={selectedOption}
                                                   onChange={(e) => { setSelectedOption(e); setChanged(true); }}
                                                   options={mediaSelect}
                                                   menuPosition="fixed"
                                                 />
                                               </div>
                                               )}
            {!editing
                        && (
                        <p>

                          {block.data.media
                                                 && (
                                                 <span className="inline-flex items-center rounded-md  px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                                   <img className="h-16 w-16" src={block.data.media.thumbnailUrl} />
                                                 </span>
                                                 )}
                        </p>
                        )}

          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            {changed
                            && (
                            <button
                              onClick={() => save()}
                              type="button"
                              className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Save
                            </button>
                            )}
            {!changed
                        && (
                        <button
                          onClick={() => setEditing(true)}
                          type="button"
                          className="mr-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Edit
                        </button>
                        )}

          </div>
        </div>
      </div>
    </div>
  );
}
