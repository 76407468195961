import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid'
import { CursorArrowRaysIcon, EnvelopeOpenIcon, UsersIcon } from '@heroicons/react/24/outline'



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Stats({analytics}) {
    const stats = [
        { id: 1, name: 'API Calls', stat: analytics.api_calls, icon: UsersIcon, change: '/ 1M', changeType: 'increase' },
        { id: 2, name: 'Projects', stat: analytics.projects, icon: EnvelopeOpenIcon, change: '/ 3', changeType: 'increase' },
        { id: 3, name: 'Users', stat: analytics.users, icon: CursorArrowRaysIcon, change: '/ 1', changeType: 'decrease' },
        { id: 4, name: 'Current Spent', stat: '12,45', icon: CursorArrowRaysIcon, change: '€', changeType: 'decrease' },
      ]
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {stats.map((item) => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-green-600 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.stat}</p>
              <p
                className='ml-2 flex items-baseline text-sm font-semibold'
                
              >
                {/* {item.changeType === 'increase' ? (
                  <ArrowUpIcon className="h-5 w-5 flex-shrink-0 self-center text-green-500" aria-hidden="true" />
                ) : (
                  <ArrowDownIcon className="h-5 w-5 flex-shrink-0 self-center text-red-500" aria-hidden="true" />
                )} */}

                {item.change}
              </p>
        
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}
