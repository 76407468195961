import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';

function ProjectDashboard() {
  const params = useParams();

  const [project, setProject] = useState([]);

  const getProjectById = () => {
    api.get(`/projects/${params.projectId}`)
      .then((data) => {
        setProject(data.data);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getProjectById();
  }, []);

  return (
    <PageHeader headline={project.name} />
  );
}

export default ProjectDashboard;
