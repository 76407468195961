import React from 'react';
import { useCookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router';

const User = () => {
  const navigate = useNavigate();
  const [cookie, setCookie] = useCookies(['token']);
  if (!cookie.token) {
    console.log('no-cookie');
    return { token: null, email: null };
  }
  // console.log("cookie")
  const { token } = cookie;
  const user = jwt_decode(token);

  // console.log(user)
  localStorage.setItem('orgId', user.org);
  return { user };
};

export default User;
