import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import camelCase from 'camelcase';
import { Switch } from '@headlessui/react';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function AddCollection() {
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);

  const [collectionName, setCollectionName] = useState('');
  const [collectionApiName, setCollectionApiName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');

  const params = useParams();

  const { t, i18n } = useTranslation();

  const createCollection = () => {
    api.post('/collections', {
      name: collectionName,
      apiName: collectionApiName,
      slug,
      description,
      isIdentical: enabled,
      blocks: [],
    })
      .then((res) => {
        console.log(res);
        navigate(`/projects/${params.projectId}/collections/${res.data.insertedId}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <>
      {/* <AppShell /> */}
      <PageHeader headline="Add Collection" saveButtonActionText="Save" saveButtonAction={createCollection} />
      {/* Add Form */}
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Collection Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">

                    <input

                      onChange={(e) => {
                        setCollectionName(e.target.value);
                        setCollectionApiName(camelCase(e.target.value));
                        setSlug(camelCase(e.target.value).toLowerCase());
                      }}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Collection Name"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Collection API Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">

                    <input
                                            // onChange={(e) => setCollectionApiName(e.target.value)}
                      value={collectionApiName}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Collection Api Name"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Slug
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">

                    <input
                                            // onChange={(e) => setCollectionApiName(e.target.value)}
                      value={slug}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Collection Api Name"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <Switch.Group as="div" className="flex items-center justify-between">

                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-green-600' : 'bg-gray-200',
                      'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2',
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                      )}
                    />
                  </Switch>
                  <span className="flex flex-grow flex-col ml-6">
                    <Switch.Label as="span" className="text-sm font-medium leading-6 text-gray-900" passive>
                      Identical Content Structure
                    </Switch.Label>
                    <Switch.Description as="span" className="text-sm text-gray-500">
                      Set to yes if all the content in this collection will have the same structure.
                    </Switch.Description>
                  </span>
                </Switch.Group>

              </div>

              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue=""
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddCollection;
