import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';
import UploadPreview from '../../components/pages/UploadPreview';

function Upload() {
  const [media, setMedia] = useState([]);

  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);

  const getMedia = () => {
    api.get('/upload')
      .then((data) => {
        console.log(data);
        setMedia(data.data);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };

  const deleteUpload = (id) => {
    api.delete(`/upload/${id}`)
      .then((data) => {
        console.log(data);
      });
    setMedia((media) => media.filter((media) => media._id !== id));
    setOpen(false);
  };

  const createMedia = (file) => {
    console.log(file);
    const formData = new FormData();
    formData.append('file', file);
    // const file = event.target.files[0];
    api.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        // handle the response
        console.log(response);
        // setAvatarUrl(response.data.thumbnailUrl)
        // setAvatarId(response.data._id)
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });
    // console.log(formData)
  };

  useEffect(() => {
    getMedia();
  }, []);
  useEffect(() => {
    getMedia();
  }, []);

  return (
    <>
      <PageHeader headline="Upload" saveButton="New" saveButtonTarget="./add" />

      <UploadPreview media={media} deleteUpload={deleteUpload} isOpen={open} openUpload={openUpload} createMedia={createMedia} />
    </>
  );
}

export default Upload;
