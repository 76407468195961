import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import camelCase from 'camelcase';
import { toast } from 'react-toastify';
import PageHeader from '../../components/appShell/PageHeader';
import api from '../../services/api';
import User from '../../hooks/auth/user';

function AddProject({ user }) {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState('');
  const [projectApiName, setProjectApiName] = useState('');
  const [slug, setSlug] = useState('');
  const [description, setDescription] = useState('');
  // const user = User();
  console.log(user);

  const { t, i18n } = useTranslation();

  const createProject = () => {
    api.post('/projects', {
      name: projectName,
      apiName: projectApiName,
      description,
      status: 'Open',
      createdBy: user.name,
      org: user.org,
    })
      .then((res) => {
        console.log(res);
        navigate(`/projects/${res.data.insertedId}`);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };
  return (
    <>
      {/* <AppShell /> */}
      <PageHeader headline="Add Project" saveButtonActionText="Save" saveButtonAction={createProject} />
      {/* Add Form */}
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Project Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">

                    <input

                      onChange={(e) => {
                        setProjectName(e.target.value);
                        setProjectApiName(camelCase(e.target.value));
                      }}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Project Name"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                  Project API Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">

                    <input
                                            // onChange={(e) => setProjectApiName(e.target.value)}
                      value={projectApiName}
                      type="text"
                      name="username"
                      id="username"
                      autoComplete="username"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="Project Api Name"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                  Description
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                    defaultValue=""
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AddProject;
