import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
// import i18next, { changeLanguage } from 'i18next';

import { useCookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import api from '../../services/api';
import User from '../../hooks/auth/user';
import i18n from '../../i18n';
import DarkModeSwitcherButton from '../../components/ui/DarkModeSwitcherButton';
import PageHeader from '../../components/appShell/PageHeader';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ProfileSettings({ user }) {
  console.log(i18n.language);
  const inputFile = useRef(null);

  const flagEnabled = useFeatureFlagEnabled('DarkModeSwitcher');

  const languages = [
    { id: 1, name: 'German', value: 'de' },
    { id: 2, name: 'English', value: 'en' },
  ];
  const theme = [
    { id: 1, name: 'Dark', value: 'dark' },
    { id: 2, name: 'Light', value: 'light' },
  ];

  // UI THEME
  const [uiTheme, setUiTheme] = useState('');

  // USER
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [avatarUrl, setAvatarUrl] = useState();
  const [avatarId, setAvatarId] = useState();
  const [profileChanged, setProfileChanged] = useState(false);
  const [uploading, setUploading] = useState(false);

  const colorTheme = () => {
    if (localStorage.getItem('color-theme') === 'dark') {
      // setDark(true)
      setUiTheme('dark');
    }
    if (localStorage.getItem('color-theme') === 'light') {
      // setDark(false)
      setUiTheme('light');
    }
  };
  console.log(uiTheme);
  const [dark, setDark] = useState();

  const setTheme = () => {
    if (uiTheme === 'dark') {
      // setDark(true)
      document.documentElement.classList.add('dark');
      document.documentElement.setAttribute('data-color-mode', 'dark');
      // localStorage.removeItem('color-theme', 'light')
      localStorage.setItem('color-theme', 'dark');
    }
    if (uiTheme === 'light') {
      // setDark(false)
      document.documentElement.classList.remove('dark');
      document.documentElement.setAttribute('data-color-mode', 'light');
      // localStorage.removeItem('color-theme', 'dark')
      localStorage.setItem('color-theme', 'light');
    }
  };

  const getProfile = () => {
    api.get(`/users/${user._id}`)
      .then((data) => {
        // console.log(data)
        setName(data.data.name);
        setEmail(data.data.email);
        setAvatarUrl(data.data.avatarUrl);
        setAvatarId(data.data.avatarId);
      });
  };

  const updateProfile = () => {
    api.patch(`/users/${user._id}`, {
      name,
      email,
      avatarUrl,
      avatarId,
    })
      .then((data) => {
        console.log(data);
        setChanges(false);
        toast.success('Saved', {
          position: 'bottom-left',
          autoClose: 2000,
        });
      })

      .catch((err) => {
        toast.error(err, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  const uploadAvatar = (file) => {
    setUploading(true);
    console.log(file);
    const formData = new FormData();
    formData.append('file', file);
    // const file = event.target.files[0];
    api.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        // handle the response
        console.log(response);
        setAvatarUrl(response.data.thumbnailUrl);
        setAvatarId(response.data._id);

        api.patch(`/users/${user._id}`, {
          avatarUrl: response.data.thumbnailUrl,
          avatarId: response.data._id,
        })
          .then((data) => {
            console.log(data);
            setChanges(false);
            setUploading(false);
            toast.success('Profile Image Saved', {
              position: 'bottom-left',
              autoClose: 2000,
            });
          })

          .catch((err) => {
            toast.error(err, {
              position: 'bottom-left',
              autoClose: 2000,
            });
          });
      })
      .catch((error) => {
        // handle errors
        console.log(error);
      });

    // console.log(formData)
  };

  useEffect(() => {
    colorTheme();
    getProfile();
  }, []);
  useEffect(() => {
    setTheme();
  }, [uiTheme]);
  useEffect(() => {
    setProfileChanged(true);
  }, [name, email, avatarUrl]);
  // const found = languages.find(e => e.value === i18next.language)
  // console.log(found)

  const [editing, setEditing] = useState(false);
  const [changes, setChanges] = useState(false);
  const [selected, setSelected] = useState({});
  const [cookies, setCookies] = useCookies();
  const [lng, setLng] = useState(i18n.language);

  const tabs = [
    { name: 'My Account', to: './', current: true },
    { name: 'Organisation', to: '../organisation', current: false },
  ];

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  // console.log()

  // console.log(selected)

  // const findLanguage = () => {
  //     const found = languages.find(e => e.value === i18n.language)
  //     setSelected(found)
  // }
  // const changeLanguage = (language) => {
  //     i18n.changeLanguage(language);
  //     setLng(language);
  //     setCookies('i18nextLng', language);
  // }

  // useEffect(() => {
  //     findLanguage()
  // }, [])

  useEffect(() => {
    setChanges(true);
  }, [selected]);

  // const saveAll = () => {
  //     changeLanguage(selected.value)
  //     setChanges(false)
  //     toast.success("Settings updated!", {
  //         position: "bottom-left",
  //         autoClose: 2000
  //     })
  // }

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {tabs.map((tab) => (
                <Link
                  key={tab.name}
                  to={tab.to}
                  className={classNames(
                    tab.current
                      ? 'border-green-500 text-green-600'
                      : 'border-transparent text-gray-500 dark:text-white hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium',
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </Link>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="space-y-12">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Appearance</h2>

            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Language
                </label>
                <select
                  id="location"
                  onChange={(e) => i18n.changeLanguage(e.target.value)}
                  name="location"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white dark:bg-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                  defaultValue={i18n.language}
                >
                  {languages.map((item) => (<option value={item.value}>{item.name}</option>))}
                </select>
              </div>
              {
                                    flagEnabled ? (
                                      <div className="sm:col-span-4">
                                        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                          Theme
                                        </label>
                                        <select
                                          id="location"
                                          onChange={(e) => setUiTheme(e.target.value)}
                                          name="location"
                                          className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white dark:bg-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                          defaultValue={uiTheme}
                                        >
                                          {theme.map((item) => (<option value={item.value}>{item.name}</option>))}
                                        </select>
                                      </div>
                                    )
                                      : (<></>)

                                }

              {/* <div className="sm:col-span-4">
                                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    Website
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
                                        <input
                                            defaultValue={org.url}
                                            onChange={(e) => setOrgUrl(e.target.value)}
                                            type="text"
                                            name="website"
                                            id="website"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="www.example.com"
                                        />
                                    </div>
                                </div>
                            </div> */}

              {/* <div className="col-span-full">
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    About
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        defaultValue={org.description}
                                        onChange={(e) => setOrgDescription(e.target.value)}
                                        id="about"
                                        name="about"
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"

                                    />
                                </div>

                            </div> */}

            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Profile</h2>

            </div>

            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
              <div className="sm:col-span-4">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Photo
                </label>
                <div className="mt-2">
                  <div className="flex sm:max-w-md">

                    <div className="mt-2 flex items-center gap-x-3">
                      {!avatarUrl && <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />}
                      {avatarUrl
                                             && (
                                             <img
                                               className="inline-block h-12 w-12 rounded-full"
                                               src={avatarUrl}
                                               alt=""
                                             />
                                             )}
                      <button
                        type="button"
                        onClick={() => onButtonClick()}
                        className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >

                        Change
                      </button>
                      {uploading
                                                && (
                                                <div role="status">
                                                  <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                  </svg>
                                                  <span className="sr-only">Loading...</span>
                                                </div>
                                                )}
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        className="hidden"
                        onChange={(e) => uploadAvatar(e.target.files[0])}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Name
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">

                    <input
                      defaultValue={name}
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      name="website"
                      id="website"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      placeholder="www.example.com"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:col-span-4">
                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Email
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">

                    <input
                      defaultValue={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      name="website"
                      id="website"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>
              </div>

              {/* <div className="sm:col-span-4">
                                <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    Theme
                                </label>
                                <select
                                    id="location"
                                    onChange={(e) => setUiTheme(e.target.value)}
                                    name="location"
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 dark:text-white dark:bg-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6"
                                    defaultValue={uiTheme}
                                >
                                    {theme.map((item) => {
                                        return (<option value={item.value}>{item.name}</option>)
                                    })}
                                </select>
                            </div> */}
              {/* <div className="sm:col-span-4">
                                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    Website
                                </label>
                                <div className="mt-2">
                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md">
                                        <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">http://</span>
                                        <input
                                            defaultValue={org.url}
                                            onChange={(e) => setOrgUrl(e.target.value)}
                                            type="text"
                                            name="website"
                                            id="website"
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 dark:text-white placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                            placeholder="www.example.com"
                                        />
                                    </div>
                                </div>
                            </div> */}

              {/* <div className="col-span-full">
                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    About
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        defaultValue={org.description}
                                        onChange={(e) => setOrgDescription(e.target.value)}
                                        id="about"
                                        name="about"
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"

                                    />
                                </div>

                            </div> */}
              {profileChanged
                                && (
                                <div className="sm:col-span-4">
                                  <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button
                                      type="button"
                                      onClick={() => setProfileChanged(false)}
                                      className="text-sm font-semibold leading-6 text-gray-900 dark:text-white"
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => updateProfile()}
                                      className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                                )}
            </div>

          </div>

        </div>
        {/* {changes &&
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button type="button"
                            onClick={() => setChanges(false)}
                            className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                            Cancel
                        </button>
                        <button
                            type="button"
                            // onClick={() => updateOrg()}
                            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Save
                        </button>
                    </div>
                } */}

      </div>
      {/* <div className="mt-6">
                <dl className="divide-y divide-gray-100 dark:divide-gray-700">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white dark:text-white">Language</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            <Listbox value={selected} onChange={setSelected}>
                                {({ open }) => (
                                    <>
                                        <div className="relative mt-2 max-w-xs">
                                            <Listbox.Button className="relative w-full cursor-default rounded-md dark:text-white dark:bg-gray-800 bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                                <span className="block truncate">{selected.name}</span>
                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400 dark:text-white" aria-hidden="true" />
                                                </span>
                                            </Listbox.Button>

                                            <Transition
                                                show={open}
                                                as={Fragment}
                                                leave="transition ease-in duration-100"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                    {languages.map((item) => (
                                                        <Listbox.Option
                                                            key={item.id}
                                                            className={({ active }) =>
                                                                classNames(
                                                                    active ? 'bg-green-600 text-white' : 'text-gray-900 dark:text-white dark:text-white',
                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                )
                                                            }
                                                            value={item}
                                                        >
                                                            {({ selected, active }) => (
                                                                <>
                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                        {item.name}
                                                                    </span>

                                                                    {selected ? (
                                                                        <span
                                                                            className={classNames(
                                                                                active ? 'text-white' : 'text-green-600',
                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                            )}
                                                                        >
                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                        </span>
                                                                    ) : null}
                                                                </>
                                                            )}
                                                        </Listbox.Option>
                                                    ))}
                                                </Listbox.Options>
                                            </Transition>
                                        </div>
                                    </>
                                )}
                            </Listbox>
                        </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white dark:text-white">Appearance</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"><DarkModeSwitcherButton /></dd>
                    </div>
                    {/* <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Email address</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">margotfoster@example.com</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">Salary expectation</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">$120,000</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900 dark:text-white">About</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                            Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur
                            qui ipsum aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                            pariatur mollit ad adipisicing reprehenderit deserunt qui eu.
                        </dd>
                                                                            </div>
                </dl>
            </div> */}
    </>
  );
}

export default ProfileSettings;
