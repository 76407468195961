import React, { useState, useEffect } from 'react';
import { useNavigate, navigate, Link } from 'react-router-dom';
import {
  Card, Label, TextInput, Checkbox, Button,
} from 'flowbite-react';
import { useAuth } from '../hooks/auth';

function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const [showA, setShowA] = useState(true);

  const loginUser = () => {
    login({ email, password });
  // navigate("/")
  // alert("Login clicked")
  };
  //     return (
  // <section class="bg-gray-50 dark:bg-gray-900">
  //   <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

  //       <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">

  //           <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
  //           <span class=" justify-center self-center text-2xl font-semibold whitespace-nowrap dark:text-white">🚀 SiteRocket</span>
  //               {/* <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
  //                   Sign in to your account
  //               </h1> */}
  //               <form class="space-y-4 md:space-y-6" action="#">
  //                   <div>
  //                       <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
  //                       <input
  //                        onChange={(e) => setEmail(e.target.value)}
  //                       type="email" name="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
  //                   </div>
  //                   <div>
  //                       <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
  //                       <input
  //                       onChange={(e) => setPassword(e.target.value)}
  //                       type="password" name="password" id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
  //                   </div>
  //                   <div class="flex items-center justify-between">
  //                       <div class="flex items-start">
  //                           <div class="flex items-center h-5">
  //                             <input id="remember" aria-describedby="remember" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
  //                           </div>
  //                           <div class="ml-3 text-sm">
  //                             <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
  //                           </div>
  //                       </div>
  //                       <a href="#" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
  //                   </div>
  //                   <button type="button" onClick={() => loginUser()} class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"  >Sign in</button>
  //                   <p class="text-sm font-light text-gray-500 dark:text-gray-400">
  //                       Don’t have an account yet? <a href="#" class="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
  //                   </p>
  //               </form>
  //           </div>
  //       </div>
  //   </div>
  // </section>
  //     )

  return (
    <>
      {/*
      This example requires updating your template:

      ```
      <html class="h-full bg-white">
      <body class="h-full">
      ```
    */}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            className="mx-auto h-10 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" action="#" method="POST">
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 dark:bg-gray-900 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:dark:text-white focus:ring-2 focus:ring-inset focus:ring-green-800 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                  Password
                </label>
                <div className="text-sm">
                  <a href="#" className="font-semibold text-green-600 hover:text-green-800">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:bg-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:dark:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-800 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="button"
                onClick={() => loginUser()}
                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Sign in
              </button>
            </div>
          </form>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?
            {' '}
            <Link to="/register" className="font-semibold leading-6 text-green-600 hover:text-green-500">
              Register
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}

export default Login;
