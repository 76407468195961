import { HomeIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumbs() {
  // console.log(pathArray)

  const location = useLocation();

  let currentLink = ''

  const crumbs = location.pathname.split('/')
    .filter(crumb => crumb !== '')
    .map((crumb) => {
    currentLink += `/${crumb}`

    return (
      <>
      <li className="text-gray-400 hover:text-gray-500">/</li>
      <li className="text-gray-400 hover:text-gray-500"><Link to={currentLink}>{crumb.charAt(0).toUpperCase() + crumb.slice(1)}</Link></li>
      </>
    )
  })

  return (
    <ol role="list" className="flex items-center space-x-2 text-xs">
      <li>
        <div>
          <Link to="/" className="text-gray-400 hover:text-gray-500">
            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </div>
      </li>
      {crumbs}
    </ol>
  );
}

export default Breadcrumbs;
