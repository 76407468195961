import React, { useEffect, useState, Fragment } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import {
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { nanoid } from 'nanoid';
import { useObserver } from 'mobx-react';
import { toJS } from 'mobx';
import Prism from 'prismjs';
import { useContentStore } from '../../../services/contentContext';
import { Item } from '../../../components/content/Item';
import BlocksList from '../../../components/blocks/BlocksList';
import SlideOver from '../../../components/content/SlideOver';
import api from '../../../services/api';
import PagesTable from '../../../components/pages/PagesTable';
import Empty from '../../../components/placeholders/Empty';
import PageHeader from '../../../components/appShell/PageHeader';
import { SortableItem } from '../../../components/ui/SortableItem';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditContent = () => {
  const contentStore = useContentStore();
  const { t, i18n } = useTranslation();
  const params = useParams();
  // console.log(params.collection)
  const [open, setOpen] = useState(false);
  const [collectionContent, setCollectionContent] = useState([]);
  const [empty, isEmpty] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [media, setMedia] = useState([]);
  const [preview, setPreview] = useState(false);
  const [collections, setCollections] = useState([]);

  // const [name, setName ] = useState();
  // const [ apiName, setApiName ] = useState();
  // const [ description, setDescription] = useState();
  // const []
  const [count, setCount] = useState('');

  const [blocks, setBlocks] = useState([]);
  console.log(blocks);

  const getCollectionContent = () => {
    api.get(`/${params.collection}/${params.id}`)
      .then((data) => {
        contentStore.clearContent();
        console.log(data.data.blocks);
        setCollectionContent(data.data);
        data.data.blocks.map((block) => contentStore.addContent(block.block));
        // setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
        // console.log(error.message)
      });
  };

  const getMedia = () => {
    api.get('/upload')
      .then((data) => {
        setMedia(data.data);
        console.log(media);
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  const updateContent = () => {
    api.patch(`/${params.collection}/${params.id}`, {
      blocks: toJS(contentStore.content),
    })
      .then((data) => {
        console.log(data);
      });
    toast.success('Saved', {
      position: 'bottom-left',
      autoClose: 2000,
    });
  };

  useEffect(() => {
    // getCollections();
    getCollectionContent();
  }, [params.collection]);

  useEffect(() => {
    // getCollections();
    getMedia();
    getCollectionContent();
    // fetchAll()
  }, []);

  console.log(contentStore.content);

  return useObserver(() => (

    <>
      {/* --------------------------- SLIDE OVER --------------------------- */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pt-16 pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Add Block
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        <BlocksList />
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <SlideOver isOpen={preview} />
      {/* {isLoading && <div class="h-5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div> } */}
      {/* {!isLoading && <PageHeader headline={params.collection.charAt(0).toUpperCase() + params.collection.slice(1)} saveButton={"New"} saveButtonTarget={"./add"} />} */}
      <PageHeader headline={collectionContent.name} saveButtonAction={updateContent} saveButtonActionText="Update" />
      <ul role="list" className="space-y-3">
        {contentStore.content.map((item) => (
          <Item media={media} block={item.block} />
        ))}
      </ul>

    </>

  ));
};

export default EditContent;
