import React from 'react';
import { useLocalStore } from 'mobx-react-lite';
import { createContentStore } from '../store/contentStore';

const ContentContext = React.createContext(null);

export function ContentProvider({ children }) {
  const contentStore = useLocalStore(createContentStore);

  return (
    <ContentContext.Provider value={contentStore}>
      {children}
    </ContentContext.Provider>
  );
}

export const useContentStore = () => React.useContext(ContentContext);
