import { useEffect, useState } from 'react';
import Select from 'react-select';
import { toJS } from 'mobx';
import { toast } from 'react-toastify';
import api from '../../services/api';
import { useContentStore } from '../../services/contentContext';

export default function Reference({ block }) {
  console.log(toJS(block));
  const getCollections = () => {
    api.get(`/${block.data.ref.slug}`)
      .then((data) => {
        setCollections(data.data);
        // console.log(media)
      })
      .catch((error) => {
        toast.error(error.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      });
  };

  useEffect(() => {
    getCollections();
  }, []);

  // const selectedCollections = block.data.refs.map((collection) => {
  //     return {
  //         id: collection._id,
  //         value: collection ,
  //         label: collection.name
  //     }
  // })

  const [collections, setCollections] = useState([]);
  const [editing, setEditing] = useState(false);
  const [changed, setChanged] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedRefs, setSelectedRefs] = useState([]);
  console.log(selectedRefs);

  const collectionSelect = collections.map((collection) => ({
    id: collection._id,
    value: collection,
    label: collection.name,
  }));

  console.log(collectionSelect);

  const contentStore = useContentStore();

  const save = () => {
    contentStore.editContent({
      type: 'reference',
      id: block.id,
      data: {
        name: block.data.name,
        ref: block.data.ref,
        refs: selectedRefs,
      },
    });
    setChanged(false);
    setEditing(false);
  };

  useEffect(() => {
    setSelectedRefs([]);
    selectedOption.map((item) => setSelectedRefs((selectedRefs) => [...selectedRefs, item.value.blocks]));
  }, [selectedOption]);

  return (
    <div key={block.id} className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-gray-900">{block.data.name}</h3>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            {editing
                            && (
                            <Select
                              defaultValue={selectedOption}
                              options={collectionSelect}
                              isMulti
                              onChange={(e) => { setSelectedOption(e); setChanged(true); }}
                            />
                            )}
            {!editing
                            && (
                            <p>
                              {/* {!selectedOption &&
                                    toJS(block.data.refs).map((item) => (
                                        <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">{item.name}</span>

                                    ))}
                                {selectedOption &&
                                    selectedOption.map((item) => (
                                        <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">{item.label}</span>

                                    ))} */}

                            </p>
                            )}

          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            {changed
                            && (
                            <button
                              onClick={() => save()}
                              type="button"
                              className="mr-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Save
                            </button>
                            )}
            {!changed
                            && (
                            <button
                              onClick={() => setEditing(true)}
                              type="button"
                              className="mr-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >
                              Edit
                            </button>
                            )}
            {/* <button
                            onClick={() => deleteOne()}
                            type="button"
                            className="mr-3 inline-flex items-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Delete
                        </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
