import { Trans, useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import { Link } from 'react-router-dom';

export default function PageHeader({
  headline,
  editButton,
  saveButton,
  editButtonTarget,
  saveButtonTarget,
  saveButtonAction,
  saveButtonActionText,
  deleteButtonAction,
  deleteButtonText,
  pendingChanges,
  pendingChangesAction,
  pendingChangesText,
  collectionApiName,
}) {
  const { t, i18n } = useTranslation();
  console.log(saveButtonAction);
  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="min-w-0 flex-1">
        <h2 className="dark:text-white text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {`${headline} `}
          {collectionApiName
                     && (
                     <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                       {collectionApiName}
                     </span>
                     )}

        </h2>
      </div>
      <div className="mt-4 flex md:ml-4 md:mt-0">
        {

            editButton
            && (
            <Link to={editButtonTarget}>
              <button
                type="button"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                {editButton}
              </button>
            </Link>
            )
          }
        {
          saveButton
          && (
          <Link to={saveButtonTarget}>
            <button
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {saveButton}
            </button>
          </Link>
          )
          }
        {
            saveButtonAction
            && (
            <button
              onClick={() => saveButtonAction()}
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {saveButtonActionText}
            </button>
            )
          }
        {
            pendingChanges
            && (
            <button
              onClick={() => pendingChangesAction()}
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-green-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {pendingChangesText}
            </button>
            )
          }
        {
            deleteButtonAction
            && (
            <button
              onClick={() => deleteButtonAction()}
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {deleteButtonText}
            </button>
            )
          }
      </div>
    </div>
  );
}
