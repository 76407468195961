export const initialState = {
  blocks: [],
};

const contentReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'ADD':
      console.log('ADD', payload);

      return {
        ...state,
        blocks: payload.blocks,
      };
    case 'REMOVE':
      console.log('REMOVE', payload);

      return {
        ...state,
        blocks: payload.blocks,
      };
    default:
      throw new Error(`No case for type ${type} found in shopReducer.`);
  }
};

export default contentReducer;
