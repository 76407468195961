import { createContext, useContext, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import authApi from '../../services/authApi';

import 'react-toastify/dist/ReactToastify.css';

const UserContext = createContext();

export function UserProvider({ children }) {
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies();

  const login = async ({ email, password }) => {
    authApi.post('/auth/login', {
      email,
      password,
    }).then((res) => {
      if (res.data.token) {
        setCookies('token', res.data.token);
        localStorage.setItem('token', res.data.token);
        navigate('/');
      }

      if (!res.data.token) {
        toast.error(res.data.message, {
          position: 'bottom-left',
          autoClose: 2000,
        });
      }
      // console.log(res)
    }).catch((error) => toast.error(error, {
      position: 'bottom-left',
      autoClose: 2000,
    }));
  };

  const logout = () => {
    ['token', 'name'].forEach((obj) => removeCookie(obj)); // remove data save in cookies
    localStorage.removeItem('orgId');
    localStorage.removeItem('token');
    navigate('/login');
  };

  const value = useMemo(
    () => ({
      cookies,
      login,
      logout,
    }),
    [cookies],
  );

  return (
    <UserContext.Provider value={value}>
      <ToastContainer />
      {children}
    </UserContext.Provider>
  );
}

export const useAuth = () => useContext(UserContext);
