import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

function UploadPreview({
  media,
  deleteUpload,
  isOpen,
  isOpenUpload,
  createMedia,
}) {
  console.log(media);
  const [open, setOpen] = useState(isOpen);
  const [openUpload, setOpenUpload] = useState(false);
  const [details, setDetails] = useState([]);
  const [showDetails, setShowDetails] = useState([]);

  const mediaDetails = () => {
    // console.log(showDetails)
    setDetails(media.find((e) => e._id === showDetails));
    console.log(details);
    // setOpen(true)
  };

  useEffect(() => {
    mediaDetails();
  }, [showDetails]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpen(false)}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pt-16 pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            Details
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              onClick={() => setOpen(false)}
                            >
                              {/* <span className="sr-only">Details</span> */}
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {details
                                                    && (
                                                    <>
                                                      <img src={details.url} />
                                                      <div className="pb-5 pt-5 sm:px-0 sm:pt-0">
                                                        <dl className="space-y-8  pt-5 sm:space-y-6 ">
                                                          <div>
                                                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Name</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                                                              <p>
                                                                {details.name}
                                                              </p>
                                                            </dd>
                                                          </div>
                                                          <div>
                                                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">URL</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                                                              <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                                                {details.url}
                                                              </span>
                                                            </dd>
                                                          </div>
                                                          <div>
                                                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Thumbnail URL</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">

                                                              <span className="inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20">
                                                                {details.thumbnailUrl}
                                                              </span>
                                                            </dd>
                                                          </div>
                                                          <div>
                                                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Created</dt>
                                                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                                                              <time dateTime="1988-06-23">{details.date}</time>
                                                            </dd>
                                                          </div>
                                                        </dl>
                                                      </div>

                                                      <button
                                                        value={details._id}
                                                        onClick={(e) => { deleteUpload(e.target.value); setOpen(false); }}
                                                        type="button"
                                                        className="my-6 inline-flex items-center rounded-md bg-red-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                                      >
                                                        Delete
                                                      </button>
                                                    </>
                                                    )}

                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <ul role="list" className="py-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        {media.map((file) => (
          <li key={file._id} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
              <img src={file.thumbnailUrl} alt="" className=" pointer-events-none object-fill group-hover:opacity-75" />
              <button type="button" className="absolute inset-0 focus:outline-none" value={file._id} onClick={(e) => { setShowDetails(e.target.value); setOpen(true); }}>
                <span className="sr-only">
                  View details for
                  {file.name}
                </span>
              </button>
            </div>
            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.name}</p>
            {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p> */}
          </li>
        ))}
      </ul>
    </>
  );
}

export default UploadPreview;
