import { createContext, useContext, useReducer } from 'react';
import {
  arrayMove,
} from '@dnd-kit/sortable';

const TasksContext = createContext(null);

const TasksDispatchContext = createContext(null);

export function TasksProvider({ children }) {
  const [tasks, dispatch] = useReducer(
    tasksReducer,
    // initialTasks
  );

  return (
    <TasksContext.Provider value={tasks}>
      <TasksDispatchContext.Provider value={dispatch}>
        {children}
      </TasksDispatchContext.Provider>
    </TasksContext.Provider>
  );
}

export function useTasks() {
  return useContext(TasksContext);
}

export function useTasksDispatch() {
  return useContext(TasksDispatchContext);
}

function tasksReducer(tasks, action) {
  switch (action.type) {
    case 'added': {
      return [...tasks, {
        id: action.id,
        text: action.text,
        done: false,
      }];
    }
    case 'changed': {
      return tasks.map((t) => {
        if (t.id === action.task.id) {
          return action.task;
        }
        return t;
      });
    }
    case 'moved': {
      console.log(action);
      console.log(action.active);
      console.log(action.over);
      const activeIndex = tasks.findIndex((tasks) => tasks.id === action.active);
      const overIndex = tasks.findIndex((tasks) => tasks.id === action.over);
      console.log(activeIndex);
      console.log(overIndex);
      return arrayMove(tasks, activeIndex, overIndex);
    }
    case 'deleted': {
      return tasks.filter((t) => t.id !== action.id);
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

const initialTasks = [{},
  // { id: 1, text: 'Philosopher’s Path', done: true },
  // { id: 2, text: 'Visit the temple', done: false },
  // { id: 3, text: 'Drink matcha', done: false }
];
